/* eslint-disable */
import { vState } from "core/valtio";
import qs from "qs";
import { axios } from "./api";

type Applications = "service_provider" | "vehicle_owner";

interface VersionData {
  versionName: string;
  versionCode: number;
  description: string;
  application: Applications;
}

interface ICities {
  city: string;
  province: string;
}

const common = {
  healthCheck: async () => {
    const url = `/ping`;
    const result = await axios.get(url);

    return result;
  },
  getCars: async (
    type: "data" | "export",
    page: number,
    sort: "NON" | "DATE" | "NAME" | "OWNER" | null,
    query: string | null,
    filter: any,
  ) => {
    const url = type === "data" ? `/plates` : "/plates/export";
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        ...(query ? { query } : undefined),
        ...(sort ? { sort } : undefined),
        ...(filter.cars ? { carId: filter.cars.id } : undefined),
        ...(filter.monthlyUsage ? { monthlyUsage: filter.monthlyUsage } : undefined),
        ...(filter.dates && filter.dates.length === 2
          ? { minDate: filter.dates[0], maxDate: filter.dates[1] }
          : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      responseType: type === "export" ? "blob" : undefined,
    });

    return result;
  },
  getUI: async () => {
    const url = `/ui`;
    const result = await axios
      .get(url)
      .then((res) => {
        console.log("UI", res);
        const data = res.data.data;
        vState.services = data.services;
        vState.categories = data.categories;
        if (data.categories.length !== 0) {
          const newArrayOfObj = data.categories.map(({ id, name }) => ({
            value: id,
            label: name,
          }));
          vState.SelectPickerCategories = newArrayOfObj;
        }
        // vState.cars = data.cars;
      })
      .catch((e) => {
        console.log("error ui", e);
      });

    return result;
  },
  getCarDetail: async (id: string) => {
    const url = `/plates/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getCarReminderDetail: async (plateId: string, id: string) => {
    const url = `/plates/${plateId}/reminders/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getServices: async (page: number, all: boolean, query: string | null) => {
    const url = `/ui/services?pageSize=${all ? "100" : "50"}&page=${page}&withRecommendations=true${
      query ? `&query=${query}` : ""
    }`;
    const result = await axios.get(url);

    return result;
  },
  getServiceDetail: async (id: string) => {
    const url = `/services/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getServiceProviders: async (
    type: "data" | "export",
    page: number,
    sort: "NON" | "DATE" | "NAME" | null,
    query: string | null,
    categories: string[] | null,
    cityId: string | null,
    archive: "all" | "actives" | "not-actives",
    dates: string[] | null,
  ) => {
    const url = `/workshops${type === "export" ? "/export" : ""}`;
    const viewStatus = archive === "all" ? null : archive === "actives" ? false : true;
    const result = await axios.get(url, {
      params: {
        pageSize: 50,
        page,
        sort,
        ...(query ? { query } : undefined),
        ...(categories ? { categories } : undefined),
        ...(cityId ? { cityId } : undefined),
        ...(viewStatus !== null ? { archive: viewStatus } : undefined),
        ...(dates && dates.length === 2 ? { minDate: dates[0], maxDate: dates[1] } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      responseType: type === "export" ? "blob" : undefined,
    });

    return result;
  },
  getServiceProvidersActivities: async (
    categories?: string[] | null,
    cityId?: string | null,
    timeLine?: [string, string] | null,
  ) => {
    const url = `/workshops/activities`;
    const result = await axios.get(url, {
      params: {
        ...(cityId ? { cityId } : undefined),
        ...(categories ? { categories } : undefined),
        ...(timeLine ? { minDate: timeLine[0], maxDate: timeLine[1] } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getServiceProvidersClusters: async (coordinates: number[], categories?: string[] | null) => {
    const url = `/workshops/bbox`;
    const result = await axios.get(url, {
      params: {
        southwestLat: coordinates[0],
        southwestLong: coordinates[1],
        northeastLat: coordinates[2],
        northeastLong: coordinates[3],
        ...(categories && categories.length !== 0 ? { categories } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  exportServiceProvidersActivities: async (
    categories?: string[] | null,
    cityId?: string | null,
    timeLine?: [string, string] | null,
  ) => {
    const url = `/workshops/activities/download`;
    const result = await axios.get(url, {
      params: {
        ...(cityId ? { cityId } : undefined),
        ...(categories ? { categories } : undefined),
        ...(timeLine ? { minDate: timeLine[0], maxDate: timeLine[1] } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      responseType: "blob",
    });

    return result;
  },
  getServiceProviderDetail: async (id: string) => {
    const url = `/workshops/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getServiceProviderPlates: async (id: string, page: number) => {
    const url = `/sessions?workshopId=${id}&pageSize=50&page=${page}`;
    const result = await axios.get(url);

    return result;
  },
  getServiceProviderFeatures: async (id: string) => {
    const url = `/UserSetting/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getServiceProviderReports: async (id: string, page: number, sort?: "NON" | "DATE" | "NAME" | null) => {
    const url = `/Reports?workshopId=${id}&pageSize=25&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
  deleteWorkshop: async (id: string) => {
    const url = `/workshops/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  getEarlyRegisters: async (page: number) => {
    const url = `/newsletter?pageSize=100&page=${page}`;
    const result = await axios.get(url);

    return result;
  },
  getUsers: async (type: "data" | "export", page: number, query: string | null, filter) => {
    const url = `/users${type === "export" ? "/export" : ""}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 30,
        page,
        ...(query ? { query } : undefined),
        ...(filter.city ? { cityId: filter.city } : undefined),
        ...(filter.date.length !== 0 ? { minDate: filter.date[0], maxDate: filter.date[1] } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      responseType: type === "export" ? "blob" : undefined,
    });

    return result;
  },
  getUserDetail: async (id: string) => {
    const url = `/users/${id}`;
    const result = await axios.get(url);

    return result;
  },
  getUsersInvitee: async (id: string, page: number) => {
    const url = `/users?refererId=${id}&pageSize=50&page=${page}`;
    const result = await axios.get(url);

    return result;
  },
  getUsersPlates: async (id: string, page: number) => {
    const url = `/plates?ownerId=${id}&pageSize=50&page=${page}`;
    const result = await axios.get(url);

    return result;
  },
  getUsersWorkshops: async (id: string, page: number) => {
    const url = `/workshops?userId=${id}&pageSize=50&page=${page}`;
    const result = await axios.get(url);

    return result;
  },
  getReports: async (page: number, sort: "NON" | "DATE" | "NAME" | null, query: string | null) => {
    const url = `/Reports?pageSize=50&page=${page}&sort=${sort}${query ? `&query=${query}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
  getFeedbacks: async (
    page: number,
    sort: "NON" | "DATE" | "NAME" | null,
    query: string | null,
    workshopId?: string | null,
    userId?: string | null,
  ) => {
    const url = `/feedbacks?pageSize=50&page=${page}&sort=${sort}${query ? `&query=${query}` : ""}${
      workshopId ? `&workshopId=${workshopId}` : ""
    }${userId ? `&userId=${userId}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
  getFeaturesReports: async () => {
    const url = `/workshops/enableFeatures`;
    const result = await axios.get(url);

    return result;
  },
  getVersions: async () => {
    const url = `/versions`;
    const result = await axios.get(url);

    return result;
  },
  addVersion: async (data: VersionData) => {
    const url = `/versions`;
    const result = await axios.post(url, data);

    return result;
  },
  updateVersion: async (id: string, data: VersionData) => {
    const url = `/versions/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deleteVersion: async (id: string) => {
    const url = `/versions/${id}`;
    const result = await axios.delete(url);

    return result;
  },
  getAllCities: async () => {
    const url = `ui/cities`;
    const result = await axios.get(url);

    return result;
  },
  addCategory: async (name: string, alias: string, features?: string[] | null) => {
    const url = `/workshops/category`;
    const result = await axios.post(url, {
      name,
      alias,
      features,
    });

    return result;
  },
  editCategory: async (id: string, name: string, alias: string, features?: string[] | null) => {
    const url = `/workshops/${id}/category`;
    const result = await axios.put(url, {
      name,
      alias,
      features,
    });

    return result;
  },
  getOwnerSettings: async () => {
    const url = `/app-configs/owner`;
    const result = await axios.get(url);

    return result;
  },
  setOwnerSettings: async (data: string[]) => {
    const url = `/app-configs/owner`;
    const result = await axios.post(url, { features: data });

    return result;
  },
  getCities: async () => {
    const url = `/ui/cities`;
    const result = await axios.get(url);

    return result;
  },
  addCity: async (data: { city: string; province: string }) => {
    const url = `/cities`;
    const result = await axios.post(url, data);

    return result;
  },
  uploadCities: async (cityList: ICities[]) => {
    const url = `/cities/batch`;
    const result = await axios.post(url, { cityList });

    return result;
  },
  downloadCities: async () => {
    const url = `/cities/batch`;
    const result = await axios.get(url);

    return result;
  },
  editCity: async (id: string, data: { city: string; province: string }) => {
    const url = `/cities/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deleteCity: async (id: string) => {
    const url = `/cities/${id}`;
    const result = await axios.delete(url);

    return result;
  },
};

export { common };
