/* eslint-disable */
import { ApiInstance, useChangeTitle, vState } from "core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectPicker } from "rsuite";
import { Breadcrumb, CardLoading, ContainerScroll, CustomLoader, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";
import { BulkSmsTabData } from "./components";

type SORT_TYPE = null | "DATE" | "NAME";

const ProvidersBulkSms = ({}) => {
  const metaTags = useChangeTitle("ویراژ - پیامک‌های سرویس دهندگان");
  const snap = useSnapshot(vState);

  const [data, setData] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const getFilteredData = (condition: string) => {
    setCardLoading(true);
    if (condition === "all") {
      setShowData(data);
    } else {
      const finalData = data.filter((item) => condition.includes(item.status));
      console.log(finalData);
      setShowData(finalData);
    }
    setTimeout(() => {
      setCardLoading(false);
    }, 400);
  };

  const getRequests = async (sort: SORT_TYPE, pageIncreased: boolean) => {
    await ApiInstance.bulkSms
      .getHistory(pageCount + 1, sort)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        setPageCount((prev) => prev + 1);
        if (pageIncreased) {
          let value = [...data, ...res.data.data];
          setShowData(value);
        } else {
          setData(res.data.data);
          setShowData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "گزارشات");
      })
      .finally(() => {
        setLoading(false);
        setCardLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getFilteredData(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    getRequests(sortReminders, false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > data.length && !loading) {
      getRequests(sortReminders, true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setCardLoading(true);
    getRequests(value, false);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  const filterItems = [
    {
      label: "همه",
      value: "all",
    },
    {
      label: "در انتظار تایید", // پرداخت موفق
      value: "payment_succeeded",
    },
    {
      label: "در انتظار ارسال", //تایید شده
      value: "approved",
    },
    {
      label: "رد شده",
      value: "rejected",
    },
    {
      label: "ارسال شده",
      value: "sent",
    },
  ];

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={26} className="">
        <Breadcrumb
          links={[
            {
              name: "پیامک",
              active: false,
              link: ``,
            },
            {
              name: "پیامک‌های سرویس دهندگان",
              active: false,
              link: ``,
            },
          ]}
          className="mb-6"
        />
        <Text type="H3" className="ts-accent mb-6 text-right">
          پیامک‌های سرویس دهندگان
        </Text>

        <div
          className={`w-full flex transition-all duration-300 ts-hide-scroll-x-but-keep-working flex-nowrap gap-2 mb-6 px-px py-px`}
        >
          {loading
            ? [1, 2, 3, 4, 5].map((item) => {
                return <Skeleton className="w-24 h-11 rounded-xl" />;
              })
            : filterItems.map((item) => {
                return (
                  <span
                    onClick={() => setSelectedFilter(item.value)}
                    className={`w-max bg-white ts-shadow rounded-xl px-2 py-3 cursor-pointer ${
                      selectedFilter === item.value ? "ts-selected-chips" : ""
                    }`}
                  >
                    <Text type="P4" className={`w-max ${selectedFilter === item.value ? "ts-primary" : "ts-accent"}`}>
                      {item.label}
                    </Text>
                  </span>
                );
              })}
        </div>

        <div className="w-full">
          {(cardLoading || data.length !== 0) && (
            <div className="w-full flex justify-between items-center mb-3">
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${showData.length} پیامک`}
                </Text>
              )}
              <SelectPicker
                size="lg"
                data={sortData}
                value={sortReminders}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              />
            </div>
          )}

          {cardLoading ? <CardLoading height="125px" className="mb-3" /> : <BulkSmsTabData data={showData} />}
          {snap.isEndOfPage && !cardLoading && (
            <div className="w-full flex justify-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </ContainerScroll>
    </>
  );
};

export { ProvidersBulkSms as default };
